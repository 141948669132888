(function () {
  'use strict';

  angular
    .module('discussions.view')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.discussions.view', {
        url: '/view/:id/:classroom_id',
        templateUrl: 'dashboard/discussions/view/view.tpl.html',
        controller: 'DiscussionsViewCtrl',
        controllerAs: 'discussionsView'
      });
  }
}());
